// import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  // CardHeader,
  // Checkbox,
  // FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  // Typography
} from '@material-ui/core';
import useAuth from '../../../../hooks/useAuth';
import firebase from '../../../../lib/firebase';

const MunicipalityCreateForm = (props) => {
  const { cou, sta, id, ...other } = props;
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <Formik
      initialValues={{
        name: '',
        id: '',
      }}
      {...other}
      validationSchema={Yup
        .object()
        .shape({
          name: Yup.string().max(255).required(),
          id: Yup.string().max(255).required(),
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          console.log(user);
          console.log(values);
          setSubmitting(true);
          firebase
            .firestore()
            .collection('data')
            .doc('data_address')
            .collection('country')
            .doc(cou)
            .collection('state')
            .doc(sta)
            .collection('city')
            .doc(id)
            .collection('municipality')
            .doc(values.id)
            .set({ name: values.name })
            .then(() => {
              toast.success('Municipio agregado a la lista exitosamente!');
              setSubmitting(false);
              navigate(`/dashboard/zonas/municipios/${cou}/${sta}/${id}`);
              return true;
            });
          return true;
          // NOTE: Make API request
        } catch (err) {
          console.error(err);
          toast.error('Something went wrong!');
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
          return true;
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        // setFieldValue,
        touched,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          {...props}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              <Card>
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={6}
                    >
                      <TextField
                        error={Boolean(touched.id && errors.id)}
                        fullWidth
                        label="ID"
                        name="id"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.id}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={6}
                    >
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        label="Nombre"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={6}
                    >
                      {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                          <FormHelperText error>
                            {errors.submit}
                          </FormHelperText>
                        </Box>
                      )}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          mt: 3
                        }}
                      >
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          // onClick={() => test(values)}
                        >
                          Agregar Municipio
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

MunicipalityCreateForm.propTypes = {
  cou: PropTypes.string.isRequired,
  sta: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default MunicipalityCreateForm;
