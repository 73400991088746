import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// import { useSelector } from '../../../store';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography } from '@material-ui/core';
// import { productApi } from '../../../__fakeApi__/productApi';
import { CountryListTable } from '../../../container/components/dashboard/zones';
import useMounted from '../../../hooks/useMounted';
import useSettings from '../../../hooks/useSettings';
import ChevronRightIcon from '../../../icons/ChevronRight';
// import DownloadIcon from '../../../icons/Download';
// import UploadIcon from '../../../icons/Upload';
import PlusIcon from '../../../icons/Plus';
import gtm from '../../../lib/gtm';
import useAuth from '../../../hooks/useAuth';
import firebase from '../../../lib/firebase';

const StateList = () => {
  const mounted = useMounted();
  const { user } = useAuth();
  const { settings } = useSettings();
  const [products, setProducts] = useState([]);
  // const { plan } = useSelector((state) => state.user);
  const plan = localStorage.getItem('plan');
  const { id } = useParams();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
    console.log(plan);
    console.log(user.id);
    firebase
      .firestore()
      .collection('data')
      .doc('data_address')
      .collection('country')
      .doc(id)
      .collection('state')
      .get()
      .then((snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          /* eslint-disable object-shorthand */
          data.push({ ...doc.data(), id: doc.id });
        });
        console.log(data);
        setProducts(data);
      });
  }, []);

  const getProducts = useCallback(async () => {
    try {
      // const data = await customerApi.getCustomers();

      // if (mounted.current) {
      //   setCustomers(data);
      // }
      setProducts([]);
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Lista de Países | MyViar - Panel Administrativo</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Lista de Países
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Lista de Países
                </Typography>
              </Breadcrumbs>
              {/* <Box
                sx={{
                  mb: -1,
                  mx: -1,
                  mt: 1
                }}
              >
                <Button
                  color="primary"
                  startIcon={<UploadIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="text"
                >
                  Import
                </Button>
                <Button
                  color="primary"
                  startIcon={<DownloadIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="text"
                >
                  Export
                </Button>
              </Box> */}
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  to={`/dashboard/zonas/nuevo-estados/${id}`}
                  variant="contained"
                >
                  Agregar Estado
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <CountryListTable
              products={products}
              route={`ciudades/${id}`}
              last={0}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default StateList;
