import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  plan: '',
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setUser(state) {
      state.plan = 'client';
    },
    setAdmin(state) {
      state.plan = 'admin';
    }
  }
});

export const { reducer } = slice;

export const setUser = () => (dispatch) => {
  dispatch(slice.actions.setUser());
};

export const setAdmin = () => (dispatch) => {
  dispatch(slice.actions.setAdmin());
};

export default slice;
